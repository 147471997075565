<template>
  <v-app >
    <v-app-bar app class="white" flat>
      <v-container>
        <v-row>
        <v-img
          class="shrink mr-2"
          contain
          :src="require('@/assets/logo.png')"
          transition="scale-transition"
          width="120"
        />
        <v-spacer/>
        <v-btn large color="primary" outlined @click="redirectToLink('https://app.kiotz.net')">
          Login
        </v-btn>
      </v-row>
      </v-container>
    </v-app-bar>
    <v-main class="main" >
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',
  methods: {
    redirectToLink (link) {
        window.location = link
    }
  },
};
</script>
<style scoped>
.main {
  background: url('@/assets/chiller2.jpg') no-repeat center center fixed !important;
  background-size: cover;
  opacity: 0.75;
}
</style>
